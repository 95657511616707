<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
        <c-card title="화기 상세정보" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <c-field 
                required
                :disabled="!isWriting"
                :editable="editable"
                :data="supWork"
                deptValue="fireSafetyManagerDeptCd"
                type="dept_user" 
                beforeText=""
                label="소방안전관리자" 
                name="fireSafetyManagerId" 
                v-model="supWork.fireSafetyManagerId"
                @dataChange="dataChange" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <c-field 
                required
                :disabled="!isWriting"
                :editable="editable"
                :data="supWork"
                deptValue="fireWatchmanDeptCd"
                type="dept_user" 
                beforeText=""
                label="화재감시자" 
                name="fireWatchmanId" 
                v-model="supWork.fireWatchmanId"
                @dataChange="dataChange" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
              <c-checkbox
                :isArray="false"
                :disabled="!isWriting"
                :editable="editable"
                codeGroupCd="SOP_FIRE_WORK_CLASSIFICATION"
                valueText="codeName"
                valueKey="code"
                label="작업구분"
                name="fireWorkClassification"
                v-model="supWork.fireWorkClassification"
                @datachange="workClassChange"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <c-text
                :disabled="!isWriting || disableEtc"
                :editable="editable"
                label="작업구분(기타)"
                name="fireWorkClassificationEtc"
                v-model="supWork.fireWorkClassificationEtc"
                @datachange="dataChange"
              />
            </div>
          </template>
        </c-card>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-table
              title="화기 체크리스트"
              :columns="gridChecklist.columns"
              :gridHeight="gridChecklist.height"
              :data="fireChecklistResults"
              :filtering="false"
              :columnSetting="false"
              :usePaging="false"
              :editable="editable&&isWriting"
            >
              <template v-slot:customArea="{ props, col }">
                <template v-if="props.row['textFlag']==='N'">
                  <q-checkbox
                    dense
                    color="orange-custom"
                    class="tableCheckBox"
                    :disable="!(editable&&isWriting)"
                    true-value="O"
                    false-value="X"
                    v-model="props.row[col.name]"
                    @input="val => checklistChange(props, col)">
                  </q-checkbox>
                </template>
                <template v-else>
                  <c-text-column
                    :editable="editable"
                    :disable="!isWriting"
                    :col="col"
                    :props="props"
                    @datachange="checklistChange(props, col)"
                  />
                </template>
              </template>
            </c-table>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div style="height:0">
              <q-banner v-if="!confiFlag" inline-actions class="text-white bg-red step-table-info-banner">
                <template v-slot:avatar>
                  <q-icon name="info_outline" color="white" />
                </template>
                밀폐 보충작업을 하지 않는 허가서입니다.
              </q-banner>
            </div>
            <c-table
              title="밀폐 체크리스트"
              :columns="gridChecklist.columns"
              :gridHeight="gridChecklist.height"
              :data="confiChecklistResults"
              :filtering="false"
              :columnSetting="false"
              :usePaging="false"
              :editable="editable&&isWriting"
            >
              <template v-slot:customArea="{ props, col }">
                <template v-if="props.row['textFlag']==='N'">
                  <q-checkbox
                    dense
                    color="orange-custom"
                    class="tableCheckBox"
                    :disable="!(editable&&isWriting)"
                    true-value="O"
                    false-value="X"
                    v-model="props.row[col.name]"
                    @input="val => checklistChange(props, col)">
                  </q-checkbox>
                </template>
                <template v-else>
                  <c-text-column
                    :editable="editable"
                    :disable="!isWriting"
                    :col="col"
                    :props="props"
                    @datachange="checklistChange(props, col)"
                  />
                </template>
              </template>
            </c-table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
        <c-table
          title="안전조치 요구사항"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="supWork.checkResults"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&isWriting"
          :contentsField="contentsField"
          @table-data-change="tableDataChange"
        >
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'swpSupFire',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          swpStepCd: '',  // 진행단계 코드
          checklistResults: [],
          deleteChecklistResults: [],
        }
      }
    },
    supWork: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          supplementWorkTypeCd: '',  // 보충작업구분
          workProposalNo: '',  // 작업 신청번호_방사선, 고소, 중장비
          workLocation: '',  // 작업장소_방사선, 고소, 중장비
          workSummary: '',  // 작업개요_고소, 방사선,굴착, 중장비
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          relationCooperation1DeptCd: '',  // 관려부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관려부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          specialRequirements: '',  // 특별요구사항
          closeMachine: '',  // (밀폐)장치명
          closeEntryResaon: '',  // (밀폐)출입사유
          closeEntryUserIds: '',  // (밀폐)출입자id_구분자이용 복수등록
          closeEntryUserNames: '',  // (밀폐)출입자명_구분자이용 복수등록
          closeExpectedRisk: '',  // (밀폐)예상위험
          blackoutCircuitBreaker: '',  // (정전)차단기기
          blackoutCircuitBreakerNo: '',  // (정전)차단기 번호
          blackoutPowerRecoveryRequestUserId: '',  // (정전)전원복구 요청자
          blackoutPowerRecoveryTime: '',  // (정전)전원복구 시간
          blackoutElectricBlockUserId: '',  // (정전)차단확인자(전기)
          blackoutMaintenanceBlockUserId: '',  // (정전)차단확인자(정비)
          radiationResponsibleUserId: '',  // (방사선)방사능 책임자
          heavyEquipSafetyCheckUserId: '',  // (중장비)안전조치 확인자
          heavyEquipManageUserId: '',  // (중장비)장비관리 책임자
          heavyEquipDriveUserId: '',  // (중장비)운전원
          heavyEquipDirectorRequirements: '',  // (중장비) 감독 요구사항
          heavyEquipRequireDeptRequirements: '',  // (중장비)요청부서 요구사항
          heavyEquipSupportDeptRequirements: '',  // (중장비) 지원부서 요구사항
          fireWatchmanId: '',  // 화재감시자
          fireWorkClassification: '',  // 화기 작업구분
          fireWorkClassificationEtc: '',  // 화기 작업구분
          fireSafetyManagerId: '',  // 소방안전관리자
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          checkResults: [], // 안전조치항목
          gases: [], // 가스항목측정 ** 사용하지 않는 보충작업도 존재하지만 빈 배열을 할당시켜 관리
          gasChecks: [], // 저장용
          gasCheckVals: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          editFlag: 'C',
          entrants: [],
        }
      }
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
    confiFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '450px'
      },
      gridChecklist: {
        columns: [
          {
            name: 'checklistItemName',
            field: 'checklistItemName',
            label: '점검내용',
            align: 'left',
            sortable: true,
          },
          {
            name: 'checklistResult',
            field: 'checklistResult',
            label: '결과',
            align: 'center',
            sortable: false,
            style: 'width:100px',
            type: 'custom',
          },
        ],
        height: '450px'
      },
      editable: true,
      listCheckItemUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
    'workPermit.plantCd'() {
      this.$set(this.supWork, 'checkResults', [])
      this.getCheckItemList();
    }
  },
  computed: {
    disableEtc() {
      return this.supWork.fireWorkClassification ? 
        this.supWork.fireWorkClassification.indexOf('FWC0000020') === -1 : true
    },
    fireChecklistResults() {
      let data = [];
      if (this.workPermit.checklistResults 
        && this.workPermit.checklistResults.length > 0) {
          data = this.$_.filter(this.workPermit.checklistResults, { supplementWorkTypeCd: 'SSWT000010' })
      }
      return data
    },
    confiChecklistResults() {
      let data = [];
      if (this.confiFlag
        && this.workPermit.checklistResults 
        && this.workPermit.checklistResults.length > 0) {
          data = this.$_.filter(this.workPermit.checklistResults, { supplementWorkTypeCd: 'SSWT000001' })
      }
      return data
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listCheckItemUrl = selectConfig.sop.swp.workChekItem.list.url
      // code setting
      // list setting
      this.getCheckItemList();
    },
    getCheckItemList() {
      /**
       * default 값을 조회
       * 
       * 초기인 경우 workPermit.checkResults에 값을 그대로 적용
       * 작성중 단계인 경우 default 값에 새로운 항목이 있을시에 workPermit.checkResults에 추가하여 입력을 받을 수 있도록 조치
       * 승인완료, 연장, 취소, 작업완료인 경우 저장된 데이터를 그대로 표시하며 이 경우 default값을 조회하지 않도록 조치
       */
      this.grid.columns = [
        // 항목
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: 'LBL0000233',
          align: 'left',
          sortable: true,
        },
        // 필요
        {
          name: 'necessaryFlag',
          field: 'necessaryFlag',
          label: 'LBL0000234',
          align: 'center',
          sortable: false,
          type: 'check',
          'true': 'Y',
          'false': 'N',
          value: 'N',
          setHeader: true,
          disableTarget: 'necessaryDisabledFlag',
          disableCon: null
        },
        // 확인
        {
          name: 'confirmFlag',
          field: 'confirmFlag',
          label: 'LBL0000235',
          align: 'center',
          sortable: false,
          type: 'check',
          disableTarget: 'necessaryFlag',
          disableCon: 'Y',
          'true': 'Y',
          'false': 'N',
          value: 'N',
          setHeader: true,
        },
      ]
      if (!this.workPermit.plantCd) return;
      if (!this.supWork.checkResults) this.supWork.checkResults = [];
      if (!this.isOld || this.workPermit.swpStepCd === 'SS00000001') {
        this.$http.url = this.listCheckItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.workPermit.plantCd,
          sopWorkTypeCd: 'SWT0000020',
          useFlag: 'Y',
        }
        this.$http.request((_result) => {
          if (!this.isOld && (!this.supWork.checkResults || this.supWork.checkResults.length === 0)) {
            // 최초인 경우 조치항목을 가지고 있지 않음으로 해당 값을 표시
            this.$_.forEach(_result.data, item => {
              this.supWork.checkResults.push({
                sopWorkPermitId: '',  // 작업허가서 일련번호
                supplementWorkTypeCd: this.supWork.supplementWorkTypeCd,  // 보충작업구분
                sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                checkItemName: item.checkItemName, // 점검항목명
                sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                necessaryFlag: 'N',  // 필요
                necessaryDisabledFlag: null,
                confirmFlag: 'N',  // 확인
                confirmUserId: '',  // 확인자
                confirmUserName: '',  // 확인자명
                regUserId: this.$store.getters.user.userId,  // 등록자 ID
                chgUserId: '',  // 수정자 ID
                editFlag: 'C'
              })
            })
          } else {
            this.$_.forEach(_result.data, item => {
              if (this.$_.findIndex(this.supWork.checkResults, { sopCheckItemId: item.sopCheckItemId }) === -1) {
                this.supWork.checkResults.push({
                  sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
                  supplementWorkTypeCd: this.supWork.supplementWorkTypeCd,  // 보충작업구분
                  sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                  checkItemName: item.checkItemName, // 점검항목명
                  sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                  necessaryFlag: 'N',  // 필요
                  necessaryDisabledFlag: null,
                  confirmFlag: 'N',  // 확인
                  confirmUserId: '',  // 확인자
                  confirmUserName: '',  // 확인자명
                  regUserId: this.$store.getters.user.userId,  // 등록자 ID
                  chgUserId: '',  // 수정자 ID
                  editFlag: 'C'
                })
              }
            })
          }
          
          // 확인자
          if (this.$_.findIndex(_result.data, { sopCheckItemKindCd: 'SCIK000005' }) > -1 
            || this.$_.findIndex(this.supWork.checkResults, { sopCheckItemKindCd: 'SCIK000005' }) > -1) {
            this.grid.columns.push({
              name: 'confirmUserName',
              field: 'confirmUserName',
              label: 'LBL0000240',
              align: 'center',
              sortable: true,
              type: 'user',
              userId: 'confirmUserId',
              disableTarget: 'necessaryFlag',
              disableCon: 'Y',
            })
          }
          this.workClassChange();
        },);
        // 확인자
      } else {
        if (this.$_.findIndex(this.workPermit.checkResults, { sopCheckItemKindCd: 'SCIK000005' }) > -1) {
          this.grid.columns.push({
            name: 'confirmUserName',
            field: 'confirmUserName',
            label: 'LBL0000240',
            align: 'center',
            sortable: true,
            type: 'user',
            userId: 'confirmUserId',
            disableTarget: 'necessaryFlag',
            disableCon: 'Y',
          })
        }
      }
    },
    workClassChange() {
      if (this.disableEtc) {
        this.$set(this.supWork, 'fireWorkClassificationEtc', '')
      }
      let requiredDatas = ['SCI0000335', 'SCI0000336'];
      if (this.supWork.fireWorkClassification
        && (this.supWork.fireWorkClassification.indexOf('FWC0000001') > -1
          || this.supWork.fireWorkClassification.indexOf('FWC0000005') > -1
          || this.supWork.fireWorkClassification.indexOf('FWC0000010') > -1
          || this.supWork.fireWorkClassification.indexOf('FWC0000015') > -1)
      ) {
        // 용접, 용단, 땜, 연마 클릭 시 
        // 화재감시자 배치 및 소화기 배치 필수값설정
        // ** 사업장이 대구공장 밖에 없음으로 다음과 같이 셋팅
        // SCI0000335, SCI0000336
        this.$_.forEach(this.supWork.checkResults, checkResult => {
          if (requiredDatas.indexOf(checkResult.sopCheckItemId) > -1) {
            this.$set(checkResult, 'necessaryFlag', 'Y')
            this.$set(checkResult, 'necessaryDisabledFlag', 'Y')
            if (checkResult['editFlag'] !== 'C') {
              checkResult['editFlag'] = 'U'
              checkResult['chgUserId'] = this.$store.getters.user.userId
            }
          }
        })
      } else {
        this.$_.forEach(this.supWork.checkResults, checkResult => {
          if (requiredDatas.indexOf(checkResult.sopCheckItemId) > -1) {
            this.$set(checkResult, 'necessaryFlag', 'N')
            this.$set(checkResult, 'necessaryDisabledFlag', null)
            if (checkResult['editFlag'] !== 'C') {
              checkResult['editFlag'] = 'U'
              checkResult['chgUserId'] = this.$store.getters.user.userId
            }
          }
        })
      }
      this.dataChange();
    },
    dataChange() {
      if (this.supWork.editFlag !== 'C') {
        this.supWork.editFlag = 'U'
        this.supWork.chgUserId = this.$store.getters.user.userId
      }
    },
    contentsField(props, col) {
      /**
       * 안전조치사항 항목에서 항목 입력 타입이 '테이블_점검자' 인 경우 테이블에 표시
       * 단, 예전에 '테이블_점검자'였으며 그럼으로 인해 데이터가 저장이 된적이 있는 경우 컴포넌트 표시
       */
      let returnVal = true;
      if (col.name === 'confirmUserName') {
        returnVal = props.row['sopCheckItemKindCd'] === 'SCIK000005' || Boolean(props.row['confirmUserName'])
      }
      return returnVal
    },
    tableDataChange(props, col) {
      if (col.name === 'necessaryFlag' && props.row[col.name] !== 'Y') {
        this.$set(props.row, 'confirmFlag', 'N');
      }
    },
    checklistChange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
  }
};
</script>

<style scoped>
.step-table-info-banner {
  position: relative;
  top: 150px;
  left: 50%;
  transform: translate(-50%);
  width: 80%;
  z-index: 1000;
}
</style>